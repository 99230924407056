.hig__table {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: 'ArtifaktElement', sans-serif;
  color: black;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #eeeeee;
  background-color: #ffffff;
  position: relative;
  box-sizing: border-box;
  font-size: 13px; }
  .hig__table--disabled {
    opacity: 0.7;
    pointer-events: none; }
  .hig__table--has-frozen-rows .hig__table__header {
    box-shadow: 0 2px 4px 0 #eeeeee;
    z-index: 1; }
  .hig__table--has-frozen-rows .hig__table__table-frozen-left,
  .hig__table--has-frozen-rows .hig__table__table-frozen-right {
    z-index: 2; }
  .hig__table--has-frozen-rows .hig__table__resizing-line {
    z-index: 3; }
  .hig__table__table {
    position: absolute;
    top: 0; }
  .hig__table__table-main {
      outline: 1px solid #eeeeee;
      left: 0; }
  .hig__table__table-main .hig__table__header-column:first-child, .hig__table__table-main .hig__table__row-column:first-child {
        padding-left: 15px; }
  .hig__table__table-main .hig__table__header-column:last-child, .hig__table__table-main .hig__table__row-column:last-child {
        padding-right: 15px; }
  .hig__table__table-main .hig__table__header {
        background-color: #f8f8f8; }
  .hig__table__table-frozen-left .hig__table__header,
    .hig__table__table-frozen-left .hig__table__body, .hig__table__table-frozen-right .hig__table__header,
    .hig__table__table-frozen-right .hig__table__body {
      overflow: hidden !important; }
  .hig__table__table-frozen-left .hig__table__row-expanded,
    .hig__table__table-frozen-left .hig__table__row--customized,
    .hig__table__table-frozen-left .hig__table__header-row--customized, .hig__table__table-frozen-right .hig__table__row-expanded,
    .hig__table__table-frozen-right .hig__table__row--customized,
    .hig__table__table-frozen-right .hig__table__header-row--customized {
      display: none; }
  .hig__table__table-frozen-left {
      box-shadow: 2px 0 4px 0 #eeeeee;
      top: 0;
      left: 0; }
  .hig__table__table-frozen-left .hig__table__header-column:first-child, .hig__table__table-frozen-left .hig__table__row-column:first-child {
        padding-left: 15px; }
  .hig__table__table-frozen-left .hig__table__header-row,
      .hig__table__table-frozen-left .hig__table__row {
        padding-right: 0 !important; }
  .hig__table__table-frozen-left .hig__table__body {
        overflow-y: auto !important; }
  .hig__table__table-frozen-right {
      box-shadow: -2px 0 4px 0 #eeeeee;
      top: 0;
      right: 0; }
  .hig__table__table-frozen-right .hig__table__header-column:last-child, .hig__table__table-frozen-right .hig__table__row-column:last-child {
        padding-right: 15px; }
  .hig__table__table-frozen-right .hig__table__header-row,
      .hig__table__table-frozen-right .hig__table__row {
        padding-left: 0 !important; }
  .hig__table__table-frozen-right .hig__table__body {
        overflow-y: auto !important; }
  .hig__table__header {
    overflow: hidden !important; }
  .hig__table .hig__table__header,
  .hig__table .hig__table__body {
    outline: none; }
  .hig__table__header-row, .hig__table__row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box; }
  .hig__table__header-row {
    background-color: #f8f8f8;
    font-weight: 700; }
  .hig__table__row {
    background-color: #ffffff; }
  .hig__table__row:hover, .hig__table__row--hovered {
      background-color: #f3f3f3; }
  .hig__table__row-expanded {
    border-bottom: 1px solid #eeeeee; }
  .hig__table__header-column, .hig__table__row-column {
    min-width: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 7.5px;
    box-sizing: border-box; }
  .hig__table__header-column--align-center, .hig__table__row-column--align-center {
      justify-content: center;
      text-align: center; }
  .hig__table__header-column--align-right, .hig__table__row-column--align-right {
      justify-content: flex-end;
      text-align: right; }
  .hig__table__header-column-text, .hig__table__row-column-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .hig__table__header-column {
    position: relative;
    cursor: default; }
  .hig__table__header-column .hig__table__sort-indicator {
      display: none; }
  .hig__table__header-column--sortable:hover {
      background-color: #f3f3f3;
      cursor: pointer; }
  .hig__table__header-column--sortable:hover .hig__table__sort-indicator {
        display: block;
        color: #888888; }
  .hig__table__header-column--sorting .hig__table__sort-indicator {
      display: block; }
  .hig__table__header-column--resizing .hig__table__column-resizer {
      padding-left: 999px;
      border-color: #cccccc; }
  .hig__table__header-column-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal; }
  .hig__table__header-row--resizing .hig__table__header-column {
    background-color: transparent;
    cursor: ew-resize; }
  .hig__table__header-row--resizing .hig__table__header-column:not(.hig__table__header-column--sorting) .hig__table__sort-indicator {
      display: none; }
  .hig__table__header-row--resizing .hig__table__header-column:not(.hig__table__header-column--resizing) .hig__table__column-resizer {
      border-color: transparent; }
  .hig__table__column-resizer {
    border-color: transparent; }
  .hig__table__column-resizer:hover {
      border-color: #cccccc; }
  .hig__table__footer {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden; }
  .hig__table__empty-layer {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden; }
  .hig__table__resizing-line {
    cursor: ew-resize;
    position: absolute;
    top: 0;
    background-color: #cccccc; }
  .hig__table__expand-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 16px;
  text-align: center;
  outline: none; }
  .hig__table__column-resizer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: ew-resize;
  border-right-style: solid; }
  .hig__table__resizing-line {
  width: 3;
  -webkit-transform: translateX(-3);
          transform: translateX(-3); }

@font-face {
    font-family: 'ArtifaktElement';
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Regular.eot'); /* IE9 Compat Modes */
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Regular.woff') format('woff'), /* Pretty Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'ArtifaktElement';
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Medium.eot'); /* IE9 Compat Modes */
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Medium.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Medium.woff') format('woff'), /* Pretty Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'ArtifaktElement';
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Bold.eot'); /* IE9 Compat Modes */
    src:url('https://fonts.autodesk.com/ArtifaktElement/EOT/Artifakt%20Element%20Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF2/Artifakt%20Element%20Bold.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/WOFF/Artifakt%20Element%20Bold.woff') format('woff'), /* Pretty Modern Browsers */
        url('https://fonts.autodesk.com/ArtifaktElement/TTF/Artifakt%20Element%20Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

.hig__flyout-v1 {
  position: relative;
  display: inline-block; }

.hig__flyout-v1__action {
  display: flex; }

.hig__flyout-v1__container {
  position: absolute;
  display: table;
  width: 100%;
  z-index: 9999;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.77, 0, 0.265, 2); }

.hig__flyout-v1--exited .hig__flyout-v1__container,
  .hig__flyout-v1--exiting .hig__flyout-v1__container {
    touch-action: none;
    pointer-events: none; }

.hig__flyout-v1--hidden .hig__flyout-v1__container {
    display: none; }

.hig__flyout-v1--exiting .hig__flyout-v1__container,
.hig__flyout-v1--exited .hig__flyout-v1__container {
  opacity: 0; }

.hig__flyout-v1--exiting.hig__flyout-v1--top-left .hig__flyout-v1__container, .hig__flyout-v1--exiting.hig__flyout-v1--top-center .hig__flyout-v1__container, .hig__flyout-v1--exiting.hig__flyout-v1--top-right .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--top-left .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--top-center .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--top-right .hig__flyout-v1__container {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px); }

.hig__flyout-v1--exiting.hig__flyout-v1--right-top .hig__flyout-v1__container, .hig__flyout-v1--exiting.hig__flyout-v1--right-center .hig__flyout-v1__container, .hig__flyout-v1--exiting.hig__flyout-v1--right-bottom .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--right-top .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--right-center .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--right-bottom .hig__flyout-v1__container {
  -webkit-transform: translateX(4px);
          transform: translateX(4px); }

.hig__flyout-v1--exiting.hig__flyout-v1--bottom-left .hig__flyout-v1__container, .hig__flyout-v1--exiting.hig__flyout-v1--bottom-center .hig__flyout-v1__container, .hig__flyout-v1--exiting.hig__flyout-v1--bottom-right .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--bottom-left .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--bottom-center .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--bottom-right .hig__flyout-v1__container {
  -webkit-transform: translateY(4px);
          transform: translateY(4px); }

.hig__flyout-v1--exiting.hig__flyout-v1--left-top .hig__flyout-v1__container, .hig__flyout-v1--exiting.hig__flyout-v1--left-center .hig__flyout-v1__container, .hig__flyout-v1--exiting.hig__flyout-v1--left-bottom .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--left-top .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--left-center .hig__flyout-v1__container,
.hig__flyout-v1--exited.hig__flyout-v1--left-bottom .hig__flyout-v1__container {
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px); }

.hig__flyout-v1__pointer-wrapper {
  display: flex;
  position: absolute; }
  .hig__flyout-v1--right-top .hig__flyout-v1__pointer-wrapper,
  .hig__flyout-v1--right-center .hig__flyout-v1__pointer-wrapper,
  .hig__flyout-v1--right-bottom .hig__flyout-v1__pointer-wrapper {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .hig__flyout-v1--bottom-left .hig__flyout-v1__pointer-wrapper,
  .hig__flyout-v1--bottom-center .hig__flyout-v1__pointer-wrapper,
  .hig__flyout-v1--bottom-right .hig__flyout-v1__pointer-wrapper {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .hig__flyout-v1--left-top .hig__flyout-v1__pointer-wrapper,
  .hig__flyout-v1--left-center .hig__flyout-v1__pointer-wrapper,
  .hig__flyout-v1--left-bottom .hig__flyout-v1__pointer-wrapper {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

.hig__flyout-v1__panel-container {
  -ms-box-shadow: 0 0 4px #bec8d2;
  -o-box-shadow: 0 0 4px #bec8d2;
  box-shadow: 0 0 4px #bec8d2;
  border: 1px solid #d4dbe1;
  background-color: white;
  border-radius: 4px; }

.hig__flyout-v1__panel-container__inner {
  position: relative; }

.hig__flyout-v1__panel {
  min-width: 200px;
  max-width: 400px;
  max-height: 360px;
  padding: 12px;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

